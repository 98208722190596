/*RW : Extra CSS for JavaScriptFunctions*/
:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

::-webkit-scrollbar {
  background-color: hsl(0, 0%, 90%);// rgb(207, 226, 248);  /* Code, for meeting the designer expectation*/
  width: 8px; //16px;  /* Code, for meeting the designer expectation*/
  height: 16px;
}
::-webkit-scrollbar-button:single-button {
  background-color: rgb(174, 175, 175); //rgb(6, 104, 252); /* Code, for meeting the designer expectation*/
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='0,0 100,0 50,50'/></svg>");
}
/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='0,50 50,100 50,0'/></svg>");

}
/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(255, 255, 255)'><polygon points='0,0 0,100 50,50'/></svg>");
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(227, 229, 229, 0.815); // inset 0 0 6px rgba(37, 155, 223, 0.658); /* Code, for meeting the designer expectation*/
 
}

::-webkit-scrollbar-thumb {
 -webkit-box-shadow: inset 0 0 6px #b4b6bb; //inset 0 0 6px rgb(2, 81, 250); /* Code, for meeting the designer expectation*/
 
 background-clip: padding-box;
 border: 1px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background: #eaf5f8 //#8199f8; /* Code, for meeting the designer expectation*/
}

rwnav {    
    display: block;
    text-align: left;
    margin-bottom: 3px;
  }
  rwnav ul {
    margin: 0;
    padding:0;
    list-style: none;
  }
  .rwnav a {
    display:block; 
    background: linear-gradient(to top, #5768f3, #1c45ef);
    color:#fff; 
    text-decoration: none;
    //padding: .8em 1.8em;
    padding: 10px 10px 10px 15px;
    text-transform: uppercase;
    font-size: 80%;
    letter-spacing: 2px;
     position: relative;
     width: 300px;
     
  }
  .rwnav{  
    vertical-align: top; 
    display: inline-block;
    box-shadow: 1px -1px -1px 1px #fff, -1px 1px -1px 1px #fff, 0 0 6px 3px #fff;
    border-radius:6px;
  }
  .rwnav li{position: relative;}
  .rwnav > li { 
    float:left; 
    //border-bottom: 4px #5768f3 solid; 
    margin-right: 1px;
    border-radius: 5px 
  } 
  .rwnav > li > a { 
    margin-bottom:1px;
    box-shadow:inset 0 2em .33em -.5em #5768f3; 
  }
  .rwnav > li:hover , .rwnav > li:hover >a{  border-bottom-color:#5768f3;}
  .rwnav li:hover > a { color: #fff; }
  .rwnav > li:first-child  { border-radius: 4px 0 0 4px;} 
  .rwnav > li:first-child>a{ border-radius: 4px 0 0 0;}
  .rwnav > li:last-child  { 
    border-radius: 0 0 4px 0; 
    margin-right: 0;
  } 
  .rwnav > li:last-child >a{border-radius: 4px; }
  .rwnav li li a { margin-top:1px}
  
  .rwnav li a:not(.rw):nth-last-child(2):before { 
    content:""; 
    position: absolute; 
    height:0; 
    width: 0; 
    border: 5px solid transparent; 
    top: 50% ;
    right:10px;  
  } 
    
  
  .rwnav ul {
  position: absolute;
  white-space: nowrap;
  border-bottom: 0px solid  #5768f3;
  z-index: 1;
  left: -99999em;
 
  }
  //.rwnav > li:hover > ul {
  .rwnav > li > ul {
  left: auto;
  padding-top: 5px  ;
  min-width: 100%;
  }
  .rwnav > li li ul {  border-left:1px solid #fff;}
  
  
  .rwnav > li li:hover > ul { 
   left: 100%;
  top: -1px;
  max-height: 160px;
  overflow-x: auto;
  overflow-y: auto;
  
  }
  
  .rwnav > li > a:first-child:nth-last-child(2):before { 
  border-top-color: #fff; 
  }
  .rwnav > li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent; 
  border-bottom-color: #e6e7ee; 
  margin-top:-5px
  }
  .rwnav li li > a:first-child:nth-last-child(2):before {  
  border-left-color: #fff;
  margin-top: -5px
  }
  .rwnav li li:hover > a:first-child:nth-last-child(2):before {
  border: 5px solid transparent; 
  border-right-color: #e6e7ee;
  right: 10px; 
  }
  /* End */

  .mySearchParent {
    width: 200px;
    margin: 0 auto;
  }
  .mySearch {
    width: 100%;
    font-size: 12px;
    padding: 11px;
    border: 1px solid #5768f3;
    border-radius: 2px;
    margin: 0 auto;
}
.mySearch:focus {
  outline: none !important;
  border:1px solid #5768f3;
  width: 100%;
  margin: 0 auto;

}
.rwDisplayShow{
  display: block;
}
.rwDisplayHide{
display: none;
}
.divBGColor{
  background: linear-gradient(to top, #5768f3, #1c45ef);
}
.font-size-changer {
  display: inline-block;
  float: right;
}
textarea.rwform-control {
height: 250px !important;
}

//new code
ul>div>li>a{
  overflow:hidden; 
  white-space:nowrap; 
  text-overflow:ellipsis;
}
.rwTooltip {
  position: relative;
  inset: 0px 0px auto auto;
 }
 .rwTooltip > span{
  z-index:9999;
 }
.rwTooltip .rwTooltiptext {
  display: none;
  background-color: #a9bcc8;// #0404f8;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 2px 2px 2px;
  position: absolute;
  font-size: 12px;
  z-index: 1052;
  top: -60%;
  left: 8%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #a9bcc8 transparent transparent transparent;
  white-space: break-spaces;
  width:200px;
}
.rwTooltip:hover .rwTooltiptext {
 display: block;
 opacity: 1;
 
}
.rwnav li > ul> div> li.rwTooltip> a :first-child:nth-last-child(2)::before{
  content:none;
}

.rwnav li > ul> div> li.rwTooltip> a:hover::before{
  content:none;
  visibility: invisible;
}

.rwTooltip .rwTooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(252, 250, 250) transparent transparent transparent;
 
}

.insertImg {
  width: 22px;
  height: 15px;
  left: 0;
  float: right;
  padding-top: 10px;
  top: 0;
  padding-left: 10px;
}
.rwActive{
  display: block;
}
.rwInActive{
  display: none;
}
/* Code to show the highlighted main menu and child menu when selected*/

.show .nav-item > a.rwHighLight{
  color: #212121 !important; //#00fff3 !important; /* Commented code, for meeting the designer expectation*/
  font-weight: 700 !important;
}

.nav-link.menu-expanded{
  background-color: #ebf5f8 !important;//#11b4e0 !important;//#0f25d5 !important; /* Commented code, for meeting the designer expectation*/
}
.nav-link.menu-expanded:hover{
  background-color: #f3f3f3; // #0f25d5 !important; /* Commented code, for meeting the designer expectation*/
}

.nav-item > div.nav-link.menu-expanded>span.menu-title{
  color: #212121 !important; //#00fff3 !important; /* Commented code, for meeting the designer expectation*/
  font-weight: 700 !important;
}
.nav-link.menu-expanded.rwHighLight{
  color: #212121 !important; //#00fff3 !important; /* Commented code, for meeting the designer expectation*/
}
.nav-item.rwHighLight{
  background-color:#ebf5f8 !important; //#11b4e0 !important;  /* Commented code, for meeting the designer expectation*/
  
}

.nav-item.rwHighLight > a > span.menu-title{
  color: #212121 !important; //#00fff3 !important; /* Commented code, for meeting the designer expectation*/
  font-weight: 700 !important;
}
/*End block*/

/*For Switch*/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px; //32px;
  font-size: 12px;
  font-family: $gen-font-family !important;
  margin-top: 3px;
  margin-bottom: 3px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dbdbdb; //#eaa11b; /* Commented code, for meeting the designer expectation*/
  -webkit-transition: .4s;
  transition: .4s;
}


.slider:before {
  position: absolute;
  content: "Off";
  display: flex;
  justify-content: center;
  height: 18px; //26px;
  width: 23px; //26px;
  left: 7px; //4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  padding-top: 0px; //5px;
}

input:checked + .slider {
  background-color: #b9e06f; //#25bb25; /* Commented code, for meeting the designer expectation*/
}

input:disabled + .slider {
  background-color: #dbdbdb; //#98a198; /* Commented code, for meeting the designer expectation*/
}

input:focus + .slider {
  box-shadow: 0 0 1px #b9e06f; //#25bb25; /* Commented code, for meeting the designer expectation*/
}


input:checked + .slider:before {
  content: "On";
  display: flex;
  justify-content: center;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 45%;
}

/*End block*/

/* for fixed the header in rule table*/
.tableFixHead          { overflow: auto; width:100% ; height: 500px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; 
  background: $th-bg;
  vertical-align: middle;
  font-family: $gen-font-family !important;
  font-size: 14px !important ;
  line-height: 20px;
  color: $table-color !important;
  font-weight:600;
}

.tableFixHead td{
  border: none !important;
  border-bottom: 1px solid #eaeaea !important;
  vertical-align: middle;
  font-family: $gen-font-family !important;
  font-size: 14px !important ;
  line-height: 20px;
  color: $table-color !important;
}

table  { border-collapse: collapse; width: 100%; }
th { 
  background: $th-bg; //#8dd4ea; 
}
/* for fixed the header in rule table*/

.rwbtn{
  margin-left: 5px;;
}
.rwbtngn{
  border-color: #8862e0;
}
.rwAPIMethodRow{
  margin-top: 10px;
}

/* Code, for meeting the designer expectation*/
.date-range {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #212121;
  font-family: $sidebar-menu-font-family;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  //white-space: nowrap;
  letter-spacing: -0.1px;
  z-index: 7;
}
.last-updated {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #888888 !important;
  font-family: $gen-font-family;
  z-index: 9 !important;
}
.no-padding{
  padding-left: 0px !important;
}
.rw-icon-gen{
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 71;
}

.refresh-icon{
  top: -5%;
  left: 95%;
}
.rw-para{
  margin: 20px 0 20px 2px;
  color: #787878;
  font-family: $gen-font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  letter-spacing: -0.1px;
}
.rw-para-2{
  color: $title-color;
  font-size: 14px !important;
  letter-spacing: 0.15px;
  font-family: $gen-font-family !important;
  font-weight: 400;
}
.rw-min-height{
  min-height: 450px;
}
.rw-para-bot-mgr{
  margin-bottom: 25px !important;
}
.rw-gen-css{
  color: #787878 !important;
  font-family: $gen-font-family !important;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}
.rw-extra-bold{
  font-weight: 700 !important;
}
.rw-bold{
	font-weight: 600 !important;
}
.rw-ft-wt-level-1{
  font-weight: 500 !important;
}
.rw-ft-wt-level-2{
  font-weight: 400 !important;
}

.rw-card{
    background: #ffffff !important;
    border: 1px solid #ededed !important;
    //overflow: visible auto;
    border-radius: 10px;
    box-shadow: 0 0 22.6px 0 rgba(0, 0, 0, 0.03);
}
.dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: .2rem 1.25rem;
}
ul li {
  line-height: 1.25;
}
.rw-mgr-top-level-1{
  margin-top: 10px;
}

.rw-mgr-left-level-2{
  margin-left: 20px !important;
}
.rw-mgr-right-level-1{
  margin-right: 20px !important;
}
.rw-mgr-right-level-2{
  margin-right: 20px !important;
}
.rw-title-top{
  color: $title-color !important;
  font-size: 18px !important;
  line-height: 12px !important;
  letter-spacing: 0.15px;
  font-family: $gen-font-family !important;
  font-weight: 600;
}

.form-control.bg-dark{
 color: #ffffff !important;

}
.rw-title-level-1{
  color: $title-color !important;
  font-size: 12px !important;
  line-height: 12px !important;
  letter-spacing: 0.15px;
  z-index: 65;
}
.rw-title-level-2{
  color: $title-color !important;
  font-size: 14px !important;
  line-height: 12px !important;
  letter-spacing: 0.15px;
  font-family: $gen-font-family !important;
}
.rw-title-level-3{
  color: $title-color !important;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  font-family: $gen-font-family !important;
  position: relative;
}
.rw-sub-title-level-3{
  color: $title-color !important;
  font-size: 16px !important;
  font-family: $gen-font-family !important;
  position: relative;
}

.rw-label{
  font-family: $gen-font-family;
  color: $lable-color !important;
  font-size: 14px !important;
  line-height: 20px;
  text-align: left;
  font-weight: 500;
}
.rw-label-v2{
  color: #5a5a5a !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.rw-large{
  font-size: 28px !important;
}
.rw-tab-css{
  font-family: $sidebar-menu-font-family !important;
  background-color: $container-bg !important;
  padding: 1px 1px !important;
  margin-bottom: 2px !important;
  }
.rw-search-box{
  height: 32px !important;
  padding: 8px 14px 8px 14px;
  background: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  display: block;
  width: 226px;
  border-radius: 4px !important;
  
}
.rw-search-box-none{
  display: none;
}
.rw-search-box ::placeholder {
  color: #737373 !important;
}
.rw-search-box ::-ms-input-placeholder {
  color: #737373 !important;
}

// Code for override css for datatable
.MuiBox-root .css-19kzrtu{
	padding: 0px !important;
}
.css-9l3uo3{
  color: $table-color !important;
  font-family: $gen-font-family !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left;
}
.table {
  color:$table-color !important;
}


.table td, .table th {
  vertical-align: middle;
  font-size: 14px !important;
  line-height: 20px;
  font-family: $gen-font-family !important;
  color: $table-color !important;
  font-weight: 400;
  padding: 5px 5px;
}
.table th {
  font-weight:600 !important;
  padding: 8px 6px !important;
}
.table-bordered, .table-bordered td, .table-bordered th{
  border: none !important;
  border-top: 1px solid #eaeaea !important;
  border-bottom: 1px solid #eaeaea !important;
}
.table td{
  background-color:$td-bg !important;
}
.table-bordered th{
  border: none !important;
}
.css-ttwr4n {
  background-color:$table-color !important;
	gap: 4px !important;
  bottom: 10px !important;
  height: 2px !important;

}
/* End */

.rw-logo-image {
  flex-shrink: 0;
  position: relative;
  width: 40px;
  height: 24px;
  background: url(/static/RW-Logo.png) no-repeat center;
  background-size: cover;
  z-index: 330;
}

.std_icon_css{
  width: 24px !important;
  height: 24px !important;
  background-size: cover !important;
  margin-right: 10px;
  //margin-bottom: 3px;
  margin-left: 5px;
}
.edit_pencil_css{
  width: 25px !important;
  height: 18px !important;
  margin-right: 5px;
  margin-left: 2px;
  border: none;
}
.rw-shrink-icon{
  width: 18px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.rw-shrink-icon-info{
  width: 20px !important;
  height: 20px !important;
 }
.detail_icon_img{
  background: url(/static/detail_icon.png) no-repeat center;
}

.edit_icon_img{
  background: url(/static/edit_icon.png) no-repeat center;
}

.edit_icon_img_disabled{
   background: url(/static/edit_icon_disabled.png) no-repeat center;
}
.edit_icon_small_img{
  background: url(/static/edit_icon_small.png) no-repeat center;
}

.delete_icon_img{
  background: url(/static/delete_icon.png) no-repeat center;
}
.plus_icon_img{
  background: url(/static/plus_icon.png) no-repeat center;
  z-index: 1049;
  position: relative;
}
.plus_icon_32_img{
  background: url(/static/plus_icon_32.png) no-repeat center;
  z-index: 1049;
  position: relative;
}
.minus_icon_32_img{
  background: url(/static/minus_icon_32.png) no-repeat center;
  z-index: 1049;
  position: relative;
}
.info_icon_img{
  background: url(/static/info_icon.png) no-repeat center;
}
.export_icon_img{
  background: url(/static/export_icon.png) no-repeat center;
}

.import_icon_img{
  background: url(/static/import_icon.png) no-repeat center;
  z-index: 1049;
  position: relative;
}
.view_icon_img{
  background: url(/static/view_icon.png) no-repeat center;
}
.group_list_icon_img{
  background: url(/static/group_list_icon.png) no-repeat center;
}

.clone_icon_img{
  background: url(/static/clone_icon_img.png) no-repeat center;
}
.clone_icon_disabled {
  background: url(/static/clone_icon_disabled.png)no-repeat center;
}
.status_icon_img{
  background: url(/static/status_icon.png)no-repeat center;
}
.profile_icon{
  width: 24px;
  min-width: 24px;
  height: 24px;
}
.modal-content {
  background-color: #ffffff !important;
  border: 1px solid #ededed !important;
  border-radius: 10px !important;
 
}
.modal-title{
  color: #2e2e2e !important;
  font-family: $gen-font-family !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: left;
}
// CSS for Sub title of Dialog box
.rw_modal_sub_title{
  color: #2e2e2e;
  font-family: $gen-font-family !important;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.btn.btn-danger{
  color: #2e2e2e;
  }
.btn-danger {
    background-color: #fff !important;
    border-color: #c7c5c5 !important;
}
.btn-danger:hover {
  background-color: #fff !important;
  border-color: #c7c5c5 !important;
}
.btn-danger:focus {
  background-color: #fff !important;
  border-color: #c7c5c5 !important;
}
/* RW Button hover and focus custom css*/

.btn-primary:hover:not(:has(:not(img))){
  background-color: $button-hover-bg !important;
  color: #fff !important;
 }

 .rw-btn-primary:hover:not(:has(:not(img))){
  background-color: $button-hover-bg !important;
  color: #fff !important;
}

.btn-primary:focus:not(:has(:not(img))){
  background-color: #fff !important; //$button-focus-bg !important;
  color: #2e2e2e !important; //#fff !important;
}

.btn-primary.btn-default-bg:hover{
  background-color: $button-hover-bg !important;
  color: #fff !important;
 } 
 .btn-primary.btn-default-bg:focus{
  background-color: $button-df-bg !important;
  color: #fff !important;
 } 

.btn-primary.btn-icon-text:hover{
  background-color: $button-hover-bg !important;
  color: #fff !important;
 }

 /*
 .btn-primary.btn-icon-text:focus{
  background-color: #fff !important; //$button-focus-bg !important;
  color: #2e2e2e; //#fff !important;
}
*/




/*End*/

.form-control {
  padding: 8px 14px 8px 14px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  color: $lable-color !important;
  font-family: $gen-font-family !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 5px !important;
  height: 36px;
}
.css-wmw4vi-ReactDropdownSelect{
  color: $lable-color !important;
  font-family: $gen-font-family !important;
  border-radius: 5px !important;
}
.rw-radius-level-1{
  border-radius: 5px;
}
.rw-rectangle{
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  
}
.rw-rectangle span{
  color: $lable-color !important;
  font-family: $gen-font-family !important;
  font-size: 14px;
  font-weight: 400;
}

.badge-info
{
    cursor: pointer;
    background: #eef2f9;
    border: 1px solid #2c73eb;
    color: #2c73eb;
}

.badge {
    padding: 5px 5px 5px 5px;
	  color: #2c73eb;
    font-family: $gen-font-family !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
}
.badge:hover{
  background-color: #0b5deb !important;
  color: #fff !important;
}
.btn-outline-primary {
  background-color: $button-bg !important;
  border-color: #e6e6e6 !important;
  border-radius: 5px;
  color: #fff !important;
  margin-top: -28px;
}
.css-19kzrtu{
  padding: 10px 0px 10px 0px !important;
}
.css-11iyf0c {
	padding: 0px !important;
}
.css-11iyf0c.Mui-selected{
  color:#212121 !important;
}
.jss1 {
  width: 100% !important;
}
.css-1r1fig8{
  background-color: #f5f5f5 !important; //#95f39a !important; 
  font-family: $gen-font-family !important;
  color: $lable-color !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  }
  
.css-xe3oja {
  background-color: #f5f5f5 !important; //#ff9494 !important;
  font-family: $gen-font-family !important;
  color: $lable-color !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  }
  .css-w5jzh {
    background-color: #f5f5f5 !important; // #FDC69A !important;
}
//RW Code to show the tooltip for general purposes, block start
.rw-gen-tooltip {
  position: relative;
  display: inline-block;
}
.rw-gen-tooltip .rw-gen-tooltiptext {
  visibility: hidden;
  max-width: 250px;
  min-width: 200px;
  background-color: #4478ec;
  color: #fff !important;
  text-align: center;
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  z-index: 1;
  margin-left: 10px !important;
  font-size: 12px !important;
  top: 170%;
  left: 2%;
}
.rw-gen-tooltip:hover .rw-gen-tooltiptext {
  visibility: visible;
  //position: relative;
  margin-top: -6px;
}
.rw-gen-tooltip .rw-gen-tooltiptext::after {
  content: " ";
  position: absolute;
  //top: 50%;
  //right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  //border-color: transparent #4478ec transparent transparent;
  bottom: 100%;
  left: 5%;
  border-color: transparent transparent #4478ec transparent;
}

//RW Code to show the tooltip for general purposes, block end
.lookup_applied_t_d{
  margin-top: -15px;
  @media (min-width: 768px) {
    margin-top: -22px;
  }
}
.lookup_applied_t_d_p{
  margin-top: -15px;
  @media (min-width: 768px) {
    margin-top: -35px;
  }
}
.rw-nowrap{
  text-wrap:nowrap;
  white-space: nowrap;
}
@media screen and (max-width: 600px) {
  .rw-gen-css{
    font-size: 12px;
   }
}
.remove_left_right_mgr{
	@media (max-width: 991px) {
		margin-right: 0px !important;
		margin-left: -15px !important;
	}
}

.MuiAlert-filledSuccess .css-1cw4hi4{
  color: #20b620;
  }
  .MuiAlert-filledError .css-1cw4hi4{
  color: #ff4848;
  }
  .grid-margin{
    margin-bottom: 0px;
  }
  .react-bootstrap-table-pagination{
    margin-top: 10px;
  }
  td:has(.std_icon_css){
    line-height: 1;
    vertical-align: middle;
    text-align: center !important;
  }
  
  div:has(.dropdown-menu.open.show){
    margin-bottom: 5px;
  }
  
/* RW custom css for outer screen start */
.rw-hide-on-mobile{
  @media (max-width: 767px) {
		display: none;
	}
}
.rw-left-panel-sign-in{
  top: 0;
  left: 0;
  background-color: #F4F9FA;
}
.rw-left-panel-sign-img{
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
}
.rw-out-sc-img-mgr{
  padding-top: 70px;
}
.rw-out-sc-mgr{
  padding-top: 40px;
}
.rw-out-sc-mgr-v1{
  padding-top: 20px;
  padding-bottom: 20px;
}
.rw-logo-mgr{
  vertical-align: text-bottom;
  margin-left: 10px;
}
.form-control-v2{
  height: 49px !important;
}
.rw-btn-ht{
  height: 50px;
}
.rw-bot-mgr{
  margin-bottom: 40px;
}
.rw-bot-mgr-l1{
  margin-bottom: 16px;
}

.rw-out-sc-bot-label{
  color: #696f79;
  font-family: $gen-font-family !important;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 20px;
}
.rw-px-sm-5 {
  padding-left: 5rem !important;
  padding-right: 8rem !important;
}
.rw-card-bg{
  background-color: #fafafa;
    border: 1px solid #69b2d673;
    border-radius: 10px;
}
.rw-project-stats-align{
text-align: center;
}
.rw-table{
  border-collapse: collapse !important;
  border: 1px solid #c4c1c1!important;
}
.rwShow{
  display: block !important;
}
.rwHide{
  display: none !important;
}
.hr_line{
  width:100%;
  size:2;
  vertical-align: middle;
}
.rw-ellipse{
  position: absolute;
  width: 250% !important;
  height: 35% !important;
  top: -90px;
  left: -500px;
  background-size: cover;
  border-radius: 100% / 160% 180% 20% 20%;
  @media (min-width: 768px) {
		display: none;
    width: 200% !important;
	}
}
.rw-float-right{
float: right;
}
  @media (max-width: 1280px) {
    .rw-px-sm-5 {
      padding-left: 3rem !important;
      padding-right: 5rem !important;
    }
    .rw-title-level-3{
      font-size: 22px !important;
    }
    .rw-label-v2 {
      font-size: 16px !important;
    }
    .rw-out-sc-bot-label{
      font-size: 14px !important;
    }
  }
  @media (max-width: 1024px) {
    .rw-px-sm-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    }
    .rw-title-level-3{
      font-size: 20px !important;
    }
    .rw-label-v2 {
      font-size: 15px !important;
    }
    .rw-logo-mgr {
      margin-left: 0px !important;
    }
  }
  @media (max-width:991px) {
    .rw-test-mode{
      display: none;
    }
  }
  @media (max-width: 767px) {
    .rw-label-v2 {
      font-size: 14px !important;
    }
    .rw-out-sc-bot-label{
      font-size: 14px !important;
    }
  }